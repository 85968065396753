.request-form-item {
  padding: 16px;
  color: #dddddd;
  margin: auto;
  align-self: flex-start;
}
.date-select {
  display: flex;
  flex-direction: row;
}
.import {
  margin: auto auto auto 0;
}
.date-picker {
  margin: 0px 20px;
  padding: 10px;
  outline: none;
}
.report-title {
  color: #dddddd;
}
.submit-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #dddddd;
}
.export-pdf-mobile {
  display: none;
}
.export-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #dddddd;
  display: column;
}
.export-button:hover {
  text-decoration: underline;
}

.name-picker {
  width: 320px;
  margin: auto;
  color: #222222;
}
.submit-buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.DayPicker-Day {
  outline: none;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #dddddd;
  color: #111111;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #dddddd;
  color: #111111;
}
button:hover {
  text-decoration: underline;
}
.view-report-button {
  margin: auto auto auto 7vw;
}
.import-file-button {
  margin: auto;
}
.mobile {
  display: none;
}
.desktop {
  display: flex;
}

@media only screen and (max-width: 800px) {
  .date-select {
    display: flex;
    flex-direction: column;
  }
  .view-report-button {
    margin: 0px;
  }
  .import {
    margin: auto auto auto 0;
  }
  .request-form-item {
    padding: 0px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .name-picker {
    width: 200px;

  }
  .export-pdf-mobile {
    display: flex;
    align-content: center;
    margin: 0 auto 20px auto;
  }
}
